var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"agreementEditor"}},[(_vm.loadingData)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pl-1 pl-sm-8",attrs:{"col":"12","md":"8"}},[_c('v-breadcrumbs',{staticClass:"px-0",attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])}),(_vm.agreement)?_c('v-row',{staticClass:"mr-auto",attrs:{"justify":"space-between","align":"center","dense":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","title":"Volver","to":{
									name: 'ReadAgreement',
									params: {
										agreementId: this.$route.params?.agreementId,
									},
								},"icon":"","exact":"","small":""}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-chevron-left ")])],1),_c('div',{staticClass:"h4 font-weight-medium",domProps:{"textContent":_vm._s(_vm.agreement.name)}})],1)]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"text-none mb-4 mr-2",attrs:{"color":"red","outlined":""},on:{"click":() => _vm.btnCancel()}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"text-none mb-4",attrs:{"color":"primary","loading":_vm.loadingSave},on:{"click":() => _vm.saveAgreement()}},[_vm._v(" Guardar ")])],1)],1):_vm._e(),(_vm.msgError && _vm.msgError.length)?_c('div',_vm._l((_vm.msgError),function(error,index){return _c('v-alert',{key:index,attrs:{"color":"red","type":"error","dense":""}},[_vm._v(" "+_vm._s(error)+" ")])}),1):_vm._e(),(_vm.agreement)?_c('div',{staticClass:"editor-wrapper"},[_c('import-image-button',{on:{"onChange":_vm.onChangeImage}}),_c('div',{staticClass:"white",on:{"dragover":function($event){$event.preventDefault();}}},[_c('ckeditor',{attrs:{"editor":_vm.editor,"config":_vm.editorConfig},on:{"ready":_vm.onReady},model:{value:(_vm.editorData),callback:function ($$v) {_vm.editorData=$$v},expression:"editorData"}})],1)],1):_vm._e()],1),(_vm.agreement)?_c('v-col',{attrs:{"col":"12","md":"4"}},[_c('div',{ref:"wrapperFloating",style:({
						position: 'relative',
						width: '100%',
						minHeight: '100%',
					}),attrs:{"id":"wrapper-floating"}},[_c('div',{staticClass:"floating pa-2 pr-8",style:({
							width: '100%',
							'max-width': _vm.widthFloating,
						})},[[_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Observaciones ")]),_c('v-expansion-panel-content',[(_vm.agreement)?_c('observation-list',{attrs:{"agreementId":_vm.agreement?.id}}):_vm._e()],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Notas ")]),_c('v-expansion-panel-content',[(_vm.agreement)?_c('observation-box',{attrs:{"agreement":_vm.agreement}}):_vm._e()],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Lista de keywords ")]),_c('v-expansion-panel-content',_vm._l((_vm.agreement.agreementForm),function(item,index){return _c('div',{key:index,staticClass:"pa-2"},[_c('div',[_vm._v(" "+_vm._s(item.fieldAgreement.label)+" : ")]),_c('v-btn',{staticClass:"my-button justify-center align-center bold",attrs:{"icon":""},on:{"click":function($event){return _vm.copyToClipboard(
														item.fieldAgreement.key
													)},"dragstart":function($event){return _vm.dragStart(item.fieldAgreement.key)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('v-chip',{staticClass:"chip justify-center align-center",attrs:{"draggable":true},on:{"dragstart":(e) =>
														_vm.dragStart(
															e,
															item.fieldAgreement.key
														)}},[_vm._v(" "+_vm._s(item.fieldAgreement.key)+" ")])],1)}),0)],1)],1)]],2)])]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }