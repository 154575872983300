<template>
	<agreement-editor />
</template>
<script>
import AgreementEditor from '@/components/agreement/editor/AgreementEditor.vue';

export default {
	name: 'AgreementEditorView',
	components: { AgreementEditor },
};
</script>
