import { mapActions, mapState } from 'vuex';
import { $EventBus } from '@/main';
import ObservationBox from '@/components/agreement/elements/ObservationBox/ObservationBox.vue';
import DecoupledDocument from '@ckeditor/ckeditor5-build-decoupled-document';
import ObservationList from '@/components/agreement/elements/ObservationList/ObservationList.vue';
import ImportImageButton from '@/components/agreement/ImportImageButton/ImportImageButton.vue';
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/es.js';

//import htmlx from '@/views/dashboard/editor/template.js';

export default {
	name: 'AgreementEditor',
	props: {},
	data: () => ({
		editor: DecoupledDocument,
		editorData: '',
		editorConfig: {
			language: 'es',
			toolbar: {
				removeItems: ['uploadImage', 'mediaEmbed'],
			},
		},

		loadingData: false,
		loadingSave: false,
		msgError: [],
		widthFloating: '',
	}),
	computed: {
		...mapState('agreement', ['agreement', 'getTemplate']),
		agreementId() {
			return this.$route.params?.agreementId || null;
		},

		breadcrumbs() {
			return [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Abastecimiento',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'AgreementHome',
					},
				},
				{
					text: this.agreement?.name || 'Precontrato',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'ReadAgreement',
						params: {
							agreementId: this.$route.params?.agreementId,
						},
					},
				},
				{
					text: 'Editor',
					disabled: true,
					href: '/',
				},
			];
		},
		disablebtn() {
			return true;
		},
	},
	watch: {},
	created() {
		this.readAgreement();
		this.listenResize();
	},
	methods: {
		...mapActions('agreement', [
			'getAgreement',
			'cleanAgreement',
			'createAgreementWord',
		]),

		onChangeImage(tagImage) {
			this.editorData = tagImage + this.editorData;
		},

		listenResize() {
			const that = this;
			window.addEventListener(
				'resize',
				function () {
					that.setWidthFloating();
				},
				true
			);
		},

		setWidthFloating() {
			this.$nextTick(() => {
				let wrapper = this.$refs?.wrapperFloating;
				if (wrapper) {
					this.widthFloating = wrapper?.offsetWidth + 'px';
				}
			});
		},

		async readAgreement() {
			if (this.agreementId) {
				this.loadingData = true;
				const { ok, response, error } = await this.getAgreement(this.agreementId);
				if (ok) {
					this.editorData = this.agreement?.last_document_format || '';
					this.setWidthFloating();
					$EventBus.$emit('showSnack', 'success', response?.message[0]);
				} else {
					let msm = error?.data?.message;
					msm = Array.isArray(msm) ? msm.join(', ') : msm;
					this.showAlert('error', msm);
				}
				this.loadingData = false;
			}
		},

		showAlert(color = '', msm = '') {
			$EventBus.$emit('showSnack', color || 'success', msm);
		},

		onReady(editor) {
			// Insert the toolbar before the editable area.
			editor.ui
				.getEditableElement()
				.parentElement.insertBefore(
					editor.ui.view.toolbar.element,
					editor.ui.getEditableElement()
				);
		},

		async saveAgreement() {
			this.loadingSave = true;
			this.msgError = [];
			const data = {
				new_content: this.editorData,
				agreement_id: parseInt(this.agreementId),
			};
			const { ok, response, error } = await this.createAgreementWord(data);
			if (ok) {
				$EventBus.$emit('showSnack', 'success', response?.message[0]);
				this.$router.push({
					name: 'ReadAgreement',
					params: {
						agreementId: this.agreementId,
					},
				});
			} else {
				const msm = error?.data?.message;
				this.msgError = Array.isArray(msm) ? msm : [msm];
			}
			this.loadingSave = false;
		},
		async copyToClipboard(keyword) {
			try {
				await navigator.clipboard.writeText('{{' + keyword + '}}');
				setTimeout(() => {
					this.showAlert('success', 'Keyword Copiada');
				}, 100);
			} catch (error) {
				this.showAlert('success', 'Error al copiar la Keyword');
			}
		},

		dragStart(event, key) {
			event.dataTransfer.setData('text/plain', '{{' + key + '}}');
		},

		async btnCancel() {
			if (this.agreement?.last_document_format == this.editorData) {
				this.$router.push({
					name: 'ReadAgreement',
					params: {
						agreementId: this.agreementId,
					},
				});
			} else {
				const { isConfirmed } = await this.$swal.fire({
					title: null,
					text: 'Tienes cambios sin guardar. ¿Estás seguro de que quieres salir de esta página?',
					icon: 'question',
					showCancelButton: true,
					cancelButtonColor: '#0039a6',
					confirmButtonColor: '#e0e0e0',
					cancelButtonText: 'No',
					confirmButtonText: 'Sí',
					reverseButtons: true,
				});
				if (isConfirmed) {
					this.$router.push({
						name: 'ReadAgreement',
						params: {
							agreementId: this.agreementId,
						},
					});
				}
			}
		},
	},
	beforeDestroy() {
		this.cleanAgreement();
	},
	components: {
		ObservationBox,
		ObservationList,
		ImportImageButton,
	},
};
